@import '~bootstrap/dist/css/bootstrap.css';

header.site-header{
    padding: 0;
}

.event-type-1{
    background: #c29ffa;
}

.event-type-2{
    background: #efadce;
}

.event-type-3{
    background: #f1aeb5;
}

.event-type-4{
    background: #fecba1;
}

.event-type-5{
    background: #ffe69c;
}

.event-type-6{
    background: #a6e9d5;
}

.event-type-7{
    background: #9eeaf9;
}

.action-link{
    cursor: pointer;
}

#editor{
    min-height: 10em;
}

main{
    padding-bottom: 2em;
}

@media(min-width: 768px){
    header.site-header{
        background-image: url(./header.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(100vw/5.5);
    }

    .calendar-day{
        min-height: calc(100vw/9);
    }

    .calendar-date{
        padding: 0 .5em;
    }
}

@media(min-width: 1440px){

    .calendar-day{
        min-height: calc(100vw/12);
    }
}
